import React from "react"
import Layout from "../components/layout"
import SeoImage from "../images/home-bg.png"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Privacy = ({ location }) => {
  return (
    <Layout location={location} title={"Privacy"}>
      {/* <Seo
        title="Home"
        description="Intellivets was founded with the goal of empowering veterinarians and pet parents by providing access to specialized pet care through telehealth capabilities."
      /> */}
      <GatsbySeo
        title="Privacy | Intellivets"
        description=" This Privacy Policy governs the manner in which Intellivets, LLC
            collects, uses, maintains and discloses information collected from
            users (each, a “User”) of the Intellivets.com website (“Site”) or
            Telehealth Services (“Services”)"
        canonical="https://www.canonical.ie/"
        openGraph={{
          url: "https://intellivets.com/privacy",
          title: "Privacy policy | Intellivets",
          description:
            "This Privacy Policy governs the manner in which Intellivets, LLC collects, uses, maintains and discloses information collected from users (each, a “User”) of the Intellivets.com website (“Site”) or Telehealth Services (“Services”)",
          images: [
            {
              url: SeoImage,
              width: 800,
              height: 600,
              alt: "Intellivets",
            },
            // {
            //   url: "https://www.example.ie/og-image-02.jpg",
            //   width: 900,
            //   height: 800,
            //   alt: "Og Image Alt Second",
            // },
            // { url: "https://www.example.ie/og-image-03.jpg" },
            // { url: "https://www.example.ie/og-image-04.jpg" },
          ],
          site_name: "Intellivets",
        }}
      />
      <section className="section section-privacy-policy">
        <div className="container content">
          <h1>Privacy Policy</h1>
          <p>
            This Privacy Policy governs the manner in which Intellivets, LLC
            collects, uses, maintains and discloses information collected from
            users (each, a “User”) of the Intellivets.com website (“Site”) or
            Telehealth Services (“Services”) This privacy policy applies to the
            Site and all products and services offered by Intellivets, LLC.
          </p>
          <h3>Personal Identiﬁcation Information</h3>
          <p>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to, when Users visit our
            site, place an order, subscribe to the newsletter, respond to a
            survey, fill out a form, and in connection with other activities,
            services, features, or resources we make available on our Site and
            Services. Users may be asked for, as appropriate, name, email
            address, phone number, credit card information. Users may, however,
            visit our Site anonymously. We will collect personal identification
            information from Users only if they voluntarily submit such
            information to us. Users can always refuse to supply personally
            identification information, except that it may prevent them from
            engaging in certain Site or Services related activities.
          </p>
          <h3>Non-personal Identiﬁcation Information</h3>
          <p>
            We may collect non-personal identification information about Users
            whenever they interact with our Site or Services. Non-personal
            identification information may include the browser name, the type of
            computer and technical information about Users means of connection
            to our Site and Services, such as the operating system and the
            Internet service providers utilized, and other similar information.
          </p>
          <h3>Web Browser Cookies</h3>
          <p>
            Our Site may use “cookies” to enhance User experience. User’s web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. User may
            choose to set their web browser to refuse cookies, or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site and Services may not function properly.
          </p>
          <h3>How We Use Collected Information</h3>
          <p>
            Intellivets, LLC may collect and use Users personal information for
            the following purposes:
          </p>
          <ul>
            <li>
              To improve customer service.
              <br /> Information you provide helps us respond to your customer
              service requests and support needs more efficiently.
            </li>
            <li>
              To process payments.
              <br />
              We may use the information Users provide about themselves when
              placing an order only to provide service to that order. We do not
              share this information with outside parties except to the extent
              necessary to provide the service.
            </li>
            <li>
              To send periodic emails.
              <br />
              We may use the email address to send User information and updates
              pertaining to their order. It may also be used to respond to
              inquiries, questions, and/or other requests.
            </li>
          </ul>
          <h3>How We Protect Your Information</h3>
          <p>
            We adopt appropriate data collection, storage and processing
            practices, and security measures to protect against unauthorized
            access, alteration, disclosure, or destruction of your personal
            information, username, password, transaction information, and data
            stored on our Site.
          </p>
          <p>
            Sensitive and private data exchange between the Site and Services
            and its Users happens over a SSL secured communication channel, and
            is encrypted and protected with digital signatures.{" "}
          </p>
          <h3>Sharing Your Personal Information</h3>
          <p>
            We do not sell, trade, or rent Users’ personal identification
            information to others. Period.
          </p>
          <h3>VETERINARY LAWS</h3>
          <p>
            We abide by the guidelines of the PVMA (PENNSYLVANIA VETERINARY
            MEDICINE PRACTICE ACT) as stated in
            <br />
            <a
              href="https://cdn.ymaws.com/www.pavma.org/resource/resmgr/files/Advocacy_links/vet_practice_act.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://cdn.ymaws.com/www.pavma.org/resource/resmgr/files/Advocacy_links/vet_practice_act.pdf
            </a>{" "}
            as well as the principles of the Veterinary Client Patient
            Relationship (VCPR) as stated in{" "}
            <a
              href="https://www.avma.org/KB/Resources/Reference/Pages/VCPR.aspx"
              target="_blank"
              rel="noreferrer"
            >
              https://www.avma.org/KB/Resources/Reference/Pages/VCPR.aspx.
            </a>
          </p>
          <h3>Changes To This Privacy Policy</h3>
          <p>
            Intellivets, LLC has the discretion to update this Privacy Policy at
            any time. When we do, we will revise the updated date at the bottom
            of this page. We encourage Users to frequently check this page for
            any changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of modifications.
          </p>
          <h3>Your Acceptance Of These Terms</h3>
          <p>
            By using this Site, you signify your acceptance of this policy and
            terms of service. If you do not agree to this policy, please do not
            use Intellivet’s Site or Services. Your continued use of the Site or
            Services following the posting of changes to this policy will be
            deemed your acceptance of those changes.
          </p>
          <h3>Contact Us</h3>
          <p>
            If you have any questions about this EULA or Privacy Policy, the
            practices of this Site or Services, or your dealings with this Site
            or Services, please contact us at:
          </p>
          <ul>
            <li>Intellivets, LLC</li>
            <li>Philadelphia, PA 19107</li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default Privacy
